//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line camelcase
import { copySys, documentFormDeleteApi, documentFormPageApi } from '@/api/modular/fileStream/documentHead'
import { STable } from '@/components'
import { handleAppointEleHeight } from '@/utils/common.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
export default {
    name: 'list',
    components: {
        STable,
        OpenData,
    },
    data() {
        return {
            btck: 0,
            // 查詢參數
            queryParam: { documentType: 2, approvalTab: 0 },
            active: 2,
            // 表頭
            columns: [
                {
                    title: '模板名稱',
                    dataIndex: 'name',
                    ellipsis: true,
                },
                {
                    title: '是否是系統模板',
                    dataIndex: 'isSystem',
                    scopedSlots: { customRender: 'isSystem' },
                },
                {
                    title: '創建時間',
                    dataIndex: 'createTime',
                    scopedSlots: { customRender: 'createTime' },
                },
                {
                    title: '更新時間',
                    dataIndex: 'updateTime',
                    scopedSlots: { customRender: 'updateTime' },
                },
                {
                    title: '創建人',
                    dataIndex: 'userId',
                    scopedSlots: { customRender: 'userId' },
                },
                {
                    title: '操作',
                    width: '150px',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                return documentFormPageApi(Object.assign(parameter, this.queryParam, { status: this.active }))
                    .then((res) => {
                        handleAppointEleHeight('#ygh-content-box', ['.s-table-tool', '.roleSearch'], 66, res)
                        return res.data
                    })
                    .catch((err) => {
                        console.log(err)
                        return {
                            pageNo: 1,
                            pageSize: 10,
                            rainbow: [],
                            rows: [],
                            totalPage: 0,
                            totalRows: 0,
                        }
                    })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    created() {
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.s-table-tool', '.roleSearch'], 66)
    },
    methods: {
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        copyTemplate({ id }) {
            copySys(id).then((res) => {
                if (res.code === 200) {
                    this.$message.success('復制成功')
                    this.$refs.table.refresh(true)
                }
            })
        },
        //輸入搜索
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        // 刪除
        delDraft({ id }) {
            documentFormDeleteApi([id])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('刪除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        refresh() {
            this.$refs.table.refresh()
        },
    },
}
