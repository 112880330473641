//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mytable from './table.vue'
import myform from './form.vue'
export default {
  components: { mytable, myform },
  data() {
    return {
      change: 0,
    }
  },
  methods:{
    gwfs(){
      this.change = 0
      this.$router.replace({ path: '/fileStreamDocumentSend' })
    }
  }
}
